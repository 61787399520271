import { Component, PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
	errorPage: ReactNode;
}>;

type State = {
	error?: unknown;
};

export class ErrorBoundary extends Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { error: false };
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error);
	}

	render() {
		if (this.state.error) {
			return this.props.errorPage;
		}
		return this.props.children;
	}
}
