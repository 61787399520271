import React from 'react';

import { Box, Button, Link, Paper, Typography } from '@mui/material';

export const InternalError = () => (
	<Box sx={{ display: 'flex', height: '100vh' }}>
		<Paper sx={{ m: 'auto', p: 3 }}>
			<Typography variant="h1">Došlo k chybě</Typography>
			<Typography>
				Něco se pokazilo na naší straně.
				<br />
				Kdyby chyba přetrvala, neváhejte se na nás obrátit na{' '}
				<Link href="mailto:info@infigy.cz">info@infigy.cz</Link>
			</Typography>
			<Button variant="contained" sx={{ mt: 2 }} href="/">
				Zpět na úvodní stránku
			</Button>
		</Paper>
	</Box>
);
