import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthChangeEvent, Session } from '@supabase/supabase-js';
import { Navigate } from 'react-router-dom';

import { supabase } from './supabase';

type Props = {
	children?: ReactNode;
};

type SupabaseContext = {
	session: Session | null;
	sessionIsLoading: boolean;
	authEvent: AuthChangeEvent | null;
	signOut: () => Promise<void>;
	setPassword: (password: string) => Promise<void>;
};

const supabaseContext = createContext<SupabaseContext>({
	session: null,
	sessionIsLoading: false,
	authEvent: null,
	signOut: () => {
		throw new Error('not implemented');
	},
	setPassword: () => {
		throw new Error('not implemented');
	},
});

export const SupabaseProvider = ({ children }: Props) => {
	const [sessionIsLoading, setSessionIsLoading] = useState(true);
	const [session, setSession] = useState<Session | null>(null);
	const [authEvent, setAuthEvent] = useState<AuthChangeEvent | null>(null);

	useEffect(() => {
		(async () => {
			if (window.location.pathname.includes('/logout')) {
				await supabase.auth.signOut();
			}

			setSessionIsLoading(true);
			supabase.auth.getSession().then(({ data: { session } }) => {
				setSession(session);
				setSessionIsLoading(false);
			});

			supabase.auth.onAuthStateChange((event, session) => {
				setAuthEvent(event);
				setSession(session);
			});
		})();
	}, []);

	return (
		<supabaseContext.Provider
			value={{
				session,
				sessionIsLoading,
				authEvent,
				signOut: async () => {
					await supabase.auth.signOut();
				},
				setPassword: async (password: string) => {
					await supabase.auth.updateUser({ password });
				},
			}}
		>
			{children}
		</supabaseContext.Provider>
	);
};

export const useSupabase = () => useContext(supabaseContext);

export const RequireAuth = ({ children }: { children?: ReactNode }) => {
	const { session, sessionIsLoading, authEvent } = useSupabase();

	if (sessionIsLoading) {
		return null;
	}

	if (!session) {
		return <Navigate to="/auth" replace />;
	}

	if (session?.user.user_metadata.invitedShouldSetPassword) {
		return <Navigate to="/auth/set-password" replace />;
	}

	if (authEvent === 'PASSWORD_RECOVERY') {
		return <Navigate to="/auth/reset-password" replace />;
	}

	return <>{children}</>;
};
