import { Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';

const mobile = 'sm';

export const fontFamily = '"Poppins", sans-serif';

export const getTypography = (theme: Theme): TypographyOptions => ({
	fontFamily,
	fontSize: 10,

	allVariants: {
		// default
		letterSpacing: '0.025rem',
	},

	h1: {
		fontSize: 20,
		fontWeight: 600,
		lineHeight: '24px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 24,
			lineHeight: '36px',
		},
	},
	h2: {
		fontSize: 16,
		fontWeight: 600,
		lineHeight: '20px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 20,
			lineHeight: '30px',
		},
	},
	h3: {
		// sub headline
		fontSize: 14,
		fontWeight: 600,
		lineHeight: '22px',
		letterSpacing: '0.005rem',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 16,
			lineHeight: '24px',
		},
	},
	h4: {
		// small sub headline
		fontSize: 12,
		fontWeight: 600,
		lineHeight: '18px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 16,
			lineHeight: '24px',
		},
	},
	h5: {
		// normal headline
		fontSize: 12,
		lineHeight: '18px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 14,
			lineHeight: '20px',
		},
	},
	body1: {
		fontSize: 10,
		lineHeight: '15px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 14,
			lineHeight: '20px',
			letterSpacing: '0.005rem',
		},
	},
	body2: {
		// text highlight
		fontSize: 10,
		fontWeight: 600,
		lineHeight: '15px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 14,
			lineHeight: '20px',
			letterSpacing: '0.005rem',
		},
	},
	button: {
		fontSize: 12,
		fontWeight: 600,
		lineHeight: '18px',
		// desktop
		[theme.breakpoints.up(mobile)]: {
			fontSize: 14,
			lineHeight: '22px',
			letterSpacing: '0.005rem',
		},
	},
	//   button2: {
	// fontSize: 10,
	// fontWeight: 600,
	// lineHeight: '15px',
	// //   desktop
	//   [theme.breakpoints.up(mobile)]: {
	//     fontSize: 14,
	//     lineHeight: '22px',
	//     letterSpacing: '0.005rem',
	//   },
	//   },
});
