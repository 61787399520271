import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';

import { Logo } from '../atoms/icons/logo';
import { Link } from '../atoms/link';
import { Row } from '../atoms/row';
import { Me } from '../modules/layout/me';
import { Nav } from '../modules/layout/nav';
import { PageWarningAlert } from '../modules/layout/page-warning-alert';

export const MainLayout = () => {
	const [warningAlertContent, setWarningAlertContent] = useState<ReactNode | null>(null);

	return (
		<mainLayoutContext.Provider value={{ warningAlertContent, setWarningAlertContent }}>
			{warningAlertContent && <PageWarningAlert>{warningAlertContent}</PageWarningAlert>}

			<Box component="main" sx={{ minHeight: '100vh', bgcolor: 'secondary.main', pt: 7 }}>
				<Container>
					<Row justifyContent="space-between" alignItems="flex-start" sx={{ mb: 3 }}>
						<Link to="/">
							<Row gap={0.5}>
								<Logo sx={{ fontSize: 24, color: 'primary.main' }} />
								<Typography
									variant="h4"
									component="span"
									sx={{
										color: 'primary.main',
										textDecoration: 'none !important',
									}}
								>
									Infigy
								</Typography>
							</Row>
						</Link>

						<Nav />

						<Me sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'right' }} />
					</Row>

					<Outlet />
				</Container>
			</Box>
		</mainLayoutContext.Provider>
	);
};

type MainLayoutContext = {
	warningAlertContent: ReactNode | null;
	setWarningAlertContent: (content: ReactNode | null) => void;
};

const mainLayoutContext = createContext<MainLayoutContext>({
	warningAlertContent: null,
	setWarningAlertContent: () => {},
});

export const useMainLayoutContext = () => useContext(mainLayoutContext);
