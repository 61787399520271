import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container, Paper } from '@mui/material';

import { Logo } from '../atoms/icons/logo';

export const AuthLayout = () => {
	return (
		<Box component="main" sx={{ minHeight: '100vh', bgcolor: 'secondary.main', pt: 7 }}>
			<Container maxWidth="sm">
				<Paper>
					<Logo
						sx={{
							fontSize: 48,
							color: 'primary.main',
							mx: 'auto',
							display: 'block',
						}}
					/>

					<Outlet />
				</Paper>
			</Container>
		</Box>
	);
};
