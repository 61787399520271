import React from 'react';

import { Stack, StackProps } from '@mui/material';

type Props = StackProps;

export const Row = React.forwardRef((props: Props, ref) => {
	return (
		<Stack direction="row" alignItems="center" {...props} ref={ref}>
			{props.children}
		</Stack>
	);
});

Row.displayName = 'Row';
