import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { InternalError } from './pages/internal-error';
import { Router } from './routes';
import { SupabaseProvider } from './supabase';
import { ThemeProvider } from './theme';
import { ErrorBoundary } from './utils/error-boundary';
import { TrpcProvider } from './utils/trpc';
import { UserProvider } from './utils/user-provider';

export const Main = () => {
	return (
		<ThemeProvider>
			<IntlProvider locale="cs" defaultLocale="cs">
				<SnackbarProvider>
					<ConfirmProvider>
						<ErrorBoundary errorPage={<InternalError />}>
							<TrpcProvider>
								<SupabaseProvider>
									<UserProvider>
										<BrowserRouter>
											<Router />
										</BrowserRouter>
									</UserProvider>
								</SupabaseProvider>
							</TrpcProvider>
						</ErrorBoundary>
					</ConfirmProvider>
				</SnackbarProvider>
			</IntlProvider>
		</ThemeProvider>
	);
};
