import React, { forwardRef } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';

type Props = { to: To } & Omit<MUILinkProps, 'href'>;

export const Link = forwardRef<HTMLAnchorElement, Props>(function Link(props, ref) {
	return <MUILink {...props} component={RouterLink} ref={ref} />;
});
