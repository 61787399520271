import { createClient } from '@supabase/supabase-js';
import cookie from 'js-cookie';

import { config } from '../config';

export const supabase = createClient(config.SUPABASE_URL, config.SUPABASE_ANON, {
	auth: {
		storageKey: 'sb-auth-token',
		storage: {
			getItem(key) {
				return cookie.get(key) || null;
			},
			removeItem(key) {
				return cookie.remove(key);
			},
			setItem(key, value) {
				cookie.set(key, value, { sameSite: 'Lax' });
			},
		},
	},
});
