import React, { PropsWithChildren, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';

import type { AppRouter } from '../../../server/src/trpc/app-router';
import { config } from '../config';

export const trpc = createTRPCReact<AppRouter>();

type Props = PropsWithChildren<{
	//
}>;

export const TrpcProvider = ({ children }: Props) => {
	const [queryClient] = useState(() => new QueryClient());
	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [httpBatchLink({ url: config.TRPC_URL })],
		}),
	);
	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</trpc.Provider>
	);
};

export type { RouterInput, RouterOutput } from '../../../server/src/trpc/app-router';
