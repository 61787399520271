import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Close, Menu } from '@mui/icons-material';
import {
	Box,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Tab,
	Tabs,
} from '@mui/material';

import { UserRole } from '../../../../server/src/shared/user-roles';
import { useUser } from '../../utils/user-provider';
import { Me } from './me';

export const Nav = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const user = useUser();

	const [drawerIsOpen, setDrawerIsOpen] = useState(false);

	const isAdmin = user?.role === UserRole.ADMIN;
	const hasGroups = Boolean(user?.groups.length);

	const items = [
		{ label: 'Zařízení', link: '/', show: hasGroups || isAdmin },
		{ label: 'Dashboard', link: '/dashboard', show: hasGroups || isAdmin },
		{ label: 'Uživatelé', link: '/users', show: isAdmin },
		{ label: 'Skupiny', link: '/groups', show: isAdmin },
		{ label: 'Partnerská sekce', link: '/partner', show: hasGroups },
	];

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: 'divider', display: { xs: 'none', md: 'block' } }}>
				<Tabs value={pathname} onChange={(_e, link) => navigate(link)}>
					{items
						.filter((item) => item.show)
						.map((item) => (
							<Tab key={item.link} label={item.label} value={item.link} />
						))}
				</Tabs>
			</Box>

			<Box sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => setDrawerIsOpen(true)}>
				<IconButton>
					{drawerIsOpen ? <Close sx={{ fontSize: 26 }} /> : <Menu sx={{ fontSize: 26 }} />}
				</IconButton>
			</Box>

			<Drawer open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
				<List sx={{ minWidth: 240 }}>
					{items
						.filter((item) => item.show)
						.map(({ link, label }) => (
							<ListItem key={link} disablePadding>
								<ListItemButton
									selected={pathname === link}
									onClick={() => {
										navigate(link);
										setDrawerIsOpen(false);
									}}
								>
									<ListItemText primary={label} />
								</ListItemButton>
							</ListItem>
						))}
				</List>
				<Me sx={{ mt: 'auto' }} />
			</Drawer>
		</>
	);
};
