import React, { createContext, useContext } from 'react';

import { useSupabase } from '../supabase';
import { RouterOutput, trpc } from './trpc';

type Me = RouterOutput['me'];

const userContext = createContext<Me | undefined>(undefined);

export const useUser = () => {
	return useContext(userContext);
};

export const UserProvider = ({ children }) => {
	const { session } = useSupabase();

	const enabled = Boolean(session);
	const { data, isLoading } = trpc.me.useQuery(undefined, {
		enabled,
	});

	const shouldShowContent = !enabled || (enabled && !isLoading);

	return <userContext.Provider value={data}>{shouldShowContent ? children : null}</userContext.Provider>;
};
