export const config = {
	TRPC_URL: '/trpc' || process.env.API_URL,
	SUPABASE_URL: process.env.SUPABASE_URL || 'https://jclxwzbylxakraflrdje.supabase.co',
	SUPABASE_ANON:
		process.env.SUPABASE_ANON ||
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpjbHh3emJ5bHhha3JhZmxyZGplIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjY2MzYxMzgsImV4cCI6MTk4MjIxMjEzOH0.o9rDmjPAJhRKgM9Ddaw69jej0LEDntR9bPhxmRaY7ZY',
	SERVIS_MANUAL_URL: 'https://wiki.infigy.cz/Technick-wiki-Infigy-10e7c10ecadd4495b4274b72ac3d8650?pvs=4',
	USER_MANUAL_URL: 'https://wiki.infigy.cz',
	SERVIS_PHONE_NUMBER: '+420234261711',
	SERVIS_EMAIL: 'info@infigy.cz',

};
