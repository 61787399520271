import { PaletteOptions } from '@mui/material';

export const colors = {
	brightOrange: '#FF9B3D',
	lightBlue: '#F4F8FC',
	richBlack: '#071326',
	darkBlue: '#3E6879',
};

export const palette: PaletteOptions = {
	primary: {
		main: colors.brightOrange,
		contrastText: '#fff',
	},
	secondary: {
		main: colors.lightBlue,
	},
	text: {
		primary: colors.richBlack,
		disabled: colors.darkBlue,
	},
};
