'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var index = require('../index-4d2d31b6.js');



exports.createFlatProxy = index.createFlatProxy;
exports.createRecursiveProxy = index.createRecursiveProxy;
