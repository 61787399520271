import React, { Suspense } from 'react';

import { CircularProgress } from '@mui/material';

export const Loadable = (Component) =>
	function L(props) {
		return (
			<Suspense fallback={<CircularProgress />}>
				<Component {...props} />
			</Suspense>
		);
	};
