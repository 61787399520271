import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const Logo = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 13 21" {...props}>
		<path d="M4 0H9V4H4V0Z" fill="currentColor" />
		<path d="M4.33333 4V8.437V16.563H8.66667V8.437V4H13V21H9.53674e-07V4H4.33333Z" fill="currentColor" />
	</SvgIcon>
);
