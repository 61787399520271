import React, { ReactNode } from 'react';

import { Box, Link } from '@mui/material';

type Props = {
	children: ReactNode;
};

export const PageWarningAlert = ({ children }: Props) => {
	return (
		<Box
			sx={{
				py: 4,
				textAlign: 'center',
				bgcolor: 'primary.main',
				color: 'white',
				fontWeight: 'bold',
			}}
		>
			{children}
		</Box>
	);
};
