import React from 'react';

import { Link as MUILink, Stack, SxProps, Typography } from '@mui/material';

import { Link } from '../../atoms/link';
import { useSupabase } from '../../supabase';

type Props = {
	sx?: SxProps;
};

export const Me = ({ sx }: Props) => {
	const { session, signOut } = useSupabase();

	return (
		<Stack sx={sx}>
			<Typography>
				<Link sx={{ textDecoration: 'none', color: 'inherit' }} to="/me">
					{session?.user.email}
				</Link>
			</Typography>

			<MUILink
				href="#"
				onClick={(e) => {
					e.preventDefault();
					signOut();
				}}
			>
				Odhlasit se
			</MUILink>
		</Stack>
	);
};
