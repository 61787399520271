import React, { PropsWithChildren } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { theme } from './theme';

type Props = PropsWithChildren<{
	//
}>;

export const ThemeProvider = ({ children }: Props) => (
	<MUIThemeProvider theme={theme}>
		<CssBaseline />
		{children}
	</MUIThemeProvider>
);
